'use strict'
Object.defineProperty(exports, '__esModule', { value: true })
exports.Zerion =
  exports.WalletIcon =
  exports.Twitter =
  exports.Trader =
  exports.Telegram =
  exports.ProFlag =
  exports.InfinityAnimation =
  exports.GuruImage =
  exports.Discord =
  exports.Copy =
  exports.CloseIcon =
    void 0
const jsx_runtime_1 = require('react/jsx-runtime')
const CloseIcon = () =>
  (0, jsx_runtime_1.jsx)(
    'svg',
    Object.assign(
      {
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 10 10',
        width: '10',
        height: '10',
      },
      {
        children: (0, jsx_runtime_1.jsx)(
          'path',
          {
            d: 'M.13.13a.444.444 0 0 1 .629 0L5 4.371 9.241.131a.444.444 0 0 1 .629.628L5.629 5l4.24 4.241a.444.444 0 1 1-.628.629L5 5.629.759 9.869a.444.444 0 1 1-.629-.628L4.371 5 .131.759a.444.444 0 0 1 0-.629Z',
            fill: '#6D7986',
          },
          void 0
        ),
      }
    ),
    void 0
  )
exports.CloseIcon = CloseIcon
const WalletIcon = () =>
  (0, jsx_runtime_1.jsxs)(
    'svg',
    Object.assign(
      {
        width: '32',
        height: '32',
        viewBox: '0 0 32 32',
        fill: 'none',
        stroke: '#545C66',
        xmlns: 'http://www.w3.org/2000/svg',
      },
      {
        children: [
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M29 20H22.994C20.7888 19.9986 19.0014 18.2093 19 16C19 13.7907 20.7888 12.0014 22.994 12H29',
              strokeWidth: '2',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            { d: 'M24 16H23', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round' },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              fillRule: 'evenodd',
              clipRule: 'evenodd',
              d: 'M10.1289 5H21.8709C25.808 5 28.9998 7.92486 28.9998 11.5327V20.4673C28.9998 24.0751 25.808 27 21.8709 27H10.1289C6.19178 27 3 24.0751 3 20.4673V11.5327C3 7.92486 6.19178 5 10.1289 5Z',
              strokeWidth: '2',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              opacity: '0.4',
              d: 'M10 10H14',
              strokeWidth: '2',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            },
            void 0
          ),
        ],
      }
    ),
    void 0
  )
exports.WalletIcon = WalletIcon
const ProFlag = () =>
  (0, jsx_runtime_1.jsxs)(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        width: '40',
        height: '44',
        viewBox: '0 0 40 44',
        className: 'wallet-widget-dialog__free',
        'data-tip': "You're currently on the free DexGuru plan",
        'data-for': 'wallet-widget-tooltip',
        'data-class': 'short',
      },
      {
        children: [
          (0, jsx_runtime_1.jsx)(
            'path',
            { fill: 'url(#a)', d: 'M0 0h40v44l-20-7.2L0 44V0Z', className: 'flag' },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              fill: '#AAB8C2',
              d: 'M20 20a3 3 0 0 0-3 3v3h1.2v-3a1.8 1.8 0 0 1 3.6 0v3H23v-3a3 3 0 0 0-3-3Z',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              fill: '#FFAC33',
              d: 'M24 28.8c0 .66-.55 1.2-1.23 1.2h-5.54c-.68 0-1.23-.54-1.23-1.2v-3.6c0-.66.55-1.2 1.23-1.2h5.54c.68 0 1.23.54 1.23 1.2v3.6Z',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              fill: '#fff',
              d: 'M10.34 12.3v-1.27h1.56c.31 0 .57-.06.79-.18.22-.11.38-.28.5-.48.1-.21.17-.45.17-.72 0-.27-.06-.51-.18-.72a1.1 1.1 0 0 0-.5-.48 1.61 1.61 0 0 0-.78-.17h-1.56V7h1.43c.67 0 1.24.1 1.7.31.48.2.84.5 1.1.9.24.38.37.83.37 1.36v.16c0 .53-.13.99-.38 1.37-.25.38-.6.68-1.08.9a4.2 4.2 0 0 1-1.71.3h-1.43ZM9 15V7h1.56v8H9ZM16.22 15V7.05h1.56V15h-1.56Zm4.9 0-2.46-3.62h1.74L22.94 15h-1.82Zm-3.8-2.85v-1.2h2.04c.29 0 .53-.05.74-.16.2-.11.37-.27.49-.47.12-.2.17-.43.17-.69 0-.26-.05-.5-.17-.7a1.2 1.2 0 0 0-.5-.46c-.2-.11-.44-.17-.73-.17h-2.04V7.05h1.88c.64 0 1.2.09 1.66.27.48.18.84.45 1.1.82.26.36.38.83.38 1.38v.17c0 .56-.13 1.02-.39 1.38a2.3 2.3 0 0 1-1.09.8c-.46.19-1.02.28-1.66.28h-1.88ZM27.78 15c-.73 0-1.36-.12-1.9-.36a4.01 4.01 0 0 1-2.09-2.22c-.17-.45-.25-.9-.25-1.32v-.22a3.83 3.83 0 0 1 2.38-3.54A4.63 4.63 0 0 1 27.78 7c.7 0 1.31.11 1.84.34A3.8 3.8 0 0 1 32 10.88v.22a4.03 4.03 0 0 1-2.36 3.53c-.53.25-1.15.37-1.86.37Zm0-1.37c.4 0 .77-.07 1.1-.2A2.4 2.4 0 0 0 30.22 12a2.86 2.86 0 0 0 0-2.06 2.35 2.35 0 0 0-1.36-1.38c-.33-.13-.7-.2-1.1-.2-.4 0-.77.07-1.1.2-.33.13-.61.31-.85.55-.22.23-.4.51-.52.83a3.02 3.02 0 0 0 0 2.06c.12.3.3.59.52.84.24.24.52.43.85.57.33.14.7.2 1.1.2Z',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'defs',
            {
              children: (0, jsx_runtime_1.jsxs)(
                'linearGradient',
                Object.assign(
                  {
                    id: 'a',
                    x1: '0',
                    x2: '47.54',
                    y1: '0',
                    y2: '10.81',
                    gradientUnits: 'userSpaceOnUse',
                  },
                  {
                    children: [
                      (0, jsx_runtime_1.jsx)('stop', { 'stop-color': '#8F00FF' }, void 0),
                      (0, jsx_runtime_1.jsx)(
                        'stop',
                        { offset: '1', 'stop-color': '#01E1FF' },
                        void 0
                      ),
                    ],
                  }
                ),
                void 0
              ),
            },
            void 0
          ),
        ],
      }
    ),
    void 0
  )
exports.ProFlag = ProFlag
const Zerion = () =>
  (0, jsx_runtime_1.jsxs)(
    'svg',
    Object.assign(
      { xmlns: 'http://www.w3.org/2000/svg', width: '24', height: '24', viewBox: '0 0 24 24' },
      {
        children: [
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              fill: '#2962EF',
              d: 'M18 2H6a4 4 0 00-4 4v12a4 4 0 004 4h12a4 4 0 004-4V6a4 4 0 00-4-4z',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              fill: '#fff',
              d: 'M10.59 15.614l1.399-2.28-2.927.012a5943.19 5943.19 0 01-2.29 3.661c-.157.28-.092.612-.092.612 0 .2.166.381.351.381H17.21c.068 0 .123-.06.123-.134a.138.138 0 00-.04-.098L15.22 15.69a.118.118 0 00-.084-.036H10.59s-.03.007 0-.04zM13.4 8.063c-.017.021-.604.815-1.423 1.922l2.85.015c1.28-1.766 2.367-3.265 2.419-3.341.113-.163.084-.337.084-.337 0-.17-.167-.322-.352-.322H6.79c-.11 0-.165.121-.087.193L8.609 7.95a.13.13 0 00.087.034h1.905l2.798.011s.053-.004 0 .068z',
            },
            void 0
          ),
        ],
      }
    ),
    void 0
  )
exports.Zerion = Zerion
const InfinityAnimation = () =>
  (0, jsx_runtime_1.jsx)(
    'svg',
    Object.assign(
      { xmlns: 'http://www.w3.org/2000/svg', width: '42', height: '20', viewBox: '0 0 42 20' },
      {
        children: (0, jsx_runtime_1.jsx)(
          'path',
          {
            fill: 'none',
            strokeWidth: '3',
            d: 'M21 10l5.4 5.8c1.5 1.5 3.4 2.2 5.5 2.2 4.4 0 8-3.6 8-8s-3.6-8-8-8c-2.1 0-4 .7-5.5 2.2 0 0-9.2 10.2-10.9 11.6-1.1.9-1.7 1.2-1.9 1.4 0 0-1.7.8-3.6.8-2.9 0-8-2.8-8-8s5.1-8 8-8c1.9 0 3.6.8 3.6.8.9.5 1.5 1 1.9 1.4 2.3 2 4.4 4.5 5.5 5.8',
          },
          void 0
        ),
      }
    ),
    void 0
  )
exports.InfinityAnimation = InfinityAnimation
const GuruImage = () =>
  (0, jsx_runtime_1.jsxs)(
    'svg',
    Object.assign(
      {
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 226 181',
        width: '226',
        height: '181',
      },
      {
        children: [
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              fillRule: 'evenodd',
              clipRule: 'evenodd',
              d: 'm225.048 114.354.021.271.005.057c.073.895.204 2.513-2.505 5.535-5.796 7.219-44.053 26.576-75.721-5.583-1.178-1.002-1.74-2.518-2.809-3.647l8.553-9.455c1.267 2.228 2.201 3.819 2.447 4.122 5.567 7.135 18.991 14.873 33.879 16.897 8.79 1.145 15.207-.797 20.976-4.715 3.684-2.502 2.001-2.436 6.741-2.472.444-.567.975-1.4 1.53-2.27 1.153-1.809 2.408-3.777 3.19-3.839.68-.055 1.028.873 1.093 1.687.106 1.334-.792 2.722-1.641 4.033-.532.822-1.045 1.614-1.277 2.343.717-.385 1.327-1.125 1.923-1.848.77-.934 1.515-1.838 2.436-1.912.68-.054 1.127.389 1.159.796Z',
              fill: '#F1CC50',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M204.508 122.975a45.21 45.21 0 0 1 2.302 2.479c.413-1.444 1.154-4.409.816-4.71-.422-.377-5.736-4.229-6.51-3.246-.775.983 2.263 4.368 3.392 5.477Z',
              fill: '#F1CC50',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              fillRule: 'evenodd',
              clipRule: 'evenodd',
              d: 'm1.462 115.229-.021.271-.005.057c-.073.895-.204 2.513 2.505 5.535 5.796 7.219 44.053 26.576 75.72-5.583 1.179-1.002 1.74-2.518 2.81-3.647l-8.553-9.455c-1.267 2.228-2.2 3.819-2.447 4.122-5.567 7.135-18.991 14.873-33.879 16.897-8.79 1.145-15.207-.797-20.976-4.715-3.684-2.502-2.001-2.436-6.74-2.472-.445-.567-.976-1.4-1.53-2.27-1.154-1.809-2.41-3.777-3.19-3.839-.681-.055-1.029.873-1.094 1.687-.106 1.334.792 2.722 1.64 4.033.533.822 1.046 1.614 1.278 2.343-.717-.385-1.327-1.125-1.923-1.848-.77-.934-1.515-1.838-2.436-1.912-.68-.054-1.127.389-1.159.796Z',
              fill: '#F1CC50',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M213.793 108.021a5.078 5.078 0 0 0-8.189-6.007l-3.004 4.094 8.189 6.007 3.004-4.094Z',
              fill: '#FF5B6B',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M199.597 110.203a5.077 5.077 0 1 0 8.189 6.007l3.003-4.094-8.189-6.007-3.003 4.094Z',
              fill: '#B61828',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M207.788 102.716a.725.725 0 0 1 .156 1.014l-6.007 8.189a.725.725 0 0 1-1.17-.858l6.007-8.189a.725.725 0 0 1 1.014-.156Z',
              fill: '#fff',
              fillOpacity: '.3',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M158.719 109.409c-.824 3.371-4.575 7.082-7.688 9.125-1.6-1.378-4.156-3.506-4.156-4 1.504-1.772 4.35-6.488 7.499-10.755.769.734 2.54 4.091 4.345 5.63Z',
              fill: '#E9AD38',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M22.002 123.85a45.22 45.22 0 0 0-2.302 2.479c-.413-1.444-1.154-4.409-.816-4.71.422-.377 5.735-4.229 6.51-3.246.775.983-2.263 4.368-3.392 5.477Z',
              fill: '#F1CC50',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              fillRule: 'evenodd',
              clipRule: 'evenodd',
              d: 'M131.057 62.809c-2.428-.75-8.819-3.441-9.706-5.483-.615-1.429-1.024-3.198-1.298-5.24 4.985-1.156-16.25-3.402-12.563-1.632.136 2.721.068 5.307-.546 7.893-.41 1.633-1.707 2.994-3.483 3.47-.751.205-2.728.557-2.728.557-3.757 7.519-11.412 20.367-18.113 30.859 3.224 11.864 8.898 22.996 7.046 35.911-3.028-1.28-6.641.321-10.358 1.968-2.663 1.181-5.378 2.384-7.97 2.567l.023.046.009.02c-18.592 4.014-40.39 36.669-13.562 45.393 13.806 3.088 23.258-4.33 37.992-15.892 3.204-2.515 6.658-5.225 10.461-8.066 1.707-1.225 3.004-2.11 3.755-2.314 6.323 3.861 12.054 8.13 17.421 12.126 15.439 11.497 27.853 20.74 42.597 11.555 12.292-7.657 21.372-31.438-19.869-43.415-1.428-4.788-7.696-5.666-12.208-5.666-.341-5.375-.552-12.207.335-17.447 10.069-31.134 3.813-19.446-7.235-47.21ZM87.118 154.41c3.135-2.429 6.446-4.994 10.062-7.328l1.57-1.361c-.126-.072-.388-.23-.752-.451-2.395-1.448-9.205-5.569-10.924-5.333-24.103 7.894-32.466 22.76-21.888 24.437 7.528 1.194 14.193-3.969 21.932-9.964Zm73.978 9.964c-6.35 2.994-15.574-2.457-34.624-16.611 29.77-11.024 43.562 10.572 34.624 16.611Z',
              fill: '#F1CC50',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M13.25 108.92a4.935 4.935 0 0 1 8.402-5.176l2.589 4.201-8.404 5.177-2.588-4.202Z',
              fill: '#62A8FF',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M26.829 112.147a4.935 4.935 0 0 1-8.404 5.177l-2.588-4.202 8.404-5.176 2.588 4.201Z',
              fill: '#165DB3',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M19.482 104.253a.705.705 0 0 0-.23.97l5.176 8.403a.705.705 0 1 0 1.2-.74l-5.176-8.403a.705.705 0 0 0-.97-.23Z',
              fill: '#fff',
              fillOpacity: '.3',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M67.791 110.284c.824 3.371 4.575 7.082 7.688 9.125 1.6-1.378 4.156-3.506 4.156-4-1.504-1.772-4.35-6.488-7.5-10.755-.768.734-2.54 4.091-4.344 5.63ZM120.19 52.087c.249 2.113.669 4.633 1.879 6.443 0 0-3.108 4.104-8.093 4.104-4.096 0-6.88-4.22-7.017-4.356.64-2.548.57-5.095.57-7.7-5.8-2.89 16.738.638 12.661 1.509ZM114 76.097c-.888-.544-15.113-.942-15.865-1.418.615-13.065.547-11.5.615-12.385a27.76 27.76 0 0 1 2.868-.34c.341 0 1.024-.068 1.365-.068.888-.136 1.639-.476 2.39-1.02l-1.024 1.156c-.068.068-1.366 10.512 9.651 14.075Z',
              fill: '#E9AD38',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M157.408 101.631c0 3.534-9.39 13.421-14.686 14.353-.546-1.837-1.297-3.606-2.048-5.308-.546 3.199-.819 6.669-.819 10.412 0 1.225 0 2.449.068 3.742 0 .341 0 .613.068.953 0 .34.068.68.068 1.021-13.149.576-20.12 7.888-30.589 11.432-9.644-4.597-14.465-7.242-23.966-11.16.341.136.75.272 1.16.476.069-.952.205-1.905.342-2.858.273-2.177.546-4.423.546-6.532 0-2.314-.546-4.355-1.024-6.329 0-.068-.068-.136-.068-.272-.82 2.654-1.64 5.24-3.073 7.894-6.128-2.054-11.494-9.724-12.359-11.16-.205-.341-.41-.681-.546-1.021-.614-1.225-1.092-2.382-1.365-3.607 0-.068 0-.136.068-.204 2.594-2.654 4.096-7.621 5.872-13.337 3.755-12.045 8.466-27.015 26.356-28.104-3.141 11.364 9.212 13.45 9.212 13.45s16.871 2.813 16.871-12.634c0-.136-.068-.748-.137-1.497 20.323 5.395 27.366 27.54 30.049 40.29Z',
              fill: '#387BD4',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M73.234 110.355c-1.676-2.349-2.682-5.145-3.353-7.382-.215-.671-2.012-.671-1.006 2.684 1.006 3.356 3.689 7.046 5.7 9.06 2.013 2.013 6.708 5.704 8.05 6.039 1.34.336 1.676-.671 1.005-1.678-2.683 0-7.9-5.227-10.396-8.723Z',
              fill: '#A0C9FF',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            { d: 'M152.077 100.333c0 .068 0 .068 0 0h-.137.137Z', fill: '#E0E7F2' },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M110.123 152.967c-.819.272-2.721 1.459-4.359 2.684l-9.726-7.382 2.644-2.548 11.441 7.246ZM128.725 141.57l-7.852 2.722-11.403-6.056c3.619-1.225 5.189-2.314 6.965-3.13a11.033 11.033 0 0 1-.752-1.565l5.258.544 7.784 7.485Z',
              fill: '#E9AD38',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'm120.873 144.292 10.993-1.565c5.462-.748 10.583-3.062 14.816-6.532l3.892-3.199-8.466-1.565-21.235 12.861Z',
              fill: '#E9AD38',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M151.189 131.908c0 3.13-11.539 4.967-22.464 9.662-1.707-2.721-6.009-7.145-8.877-9.662 6.282-3.063 13.498-6.454 20.121-6.454 4.916 0 9.103 3.119 10.469 4.82l.068.068c.478.613.683 1.157.683 1.566Z',
              fill: '#C22704',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M128.741 141.546c1.006 3.356-4.092 4.507-5.708 2.494-2.927-3.646-4.686-4.215-7.37-8.479-2.324-3.691 4.366-5.945 5.708-4.267 3.55 3.538 5.836 5.134 7.37 10.252Z',
              fill: '#E0E7F2',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M133.983 29.767c0 6.941-2.322 13.133-6.009 17.557-3.414 4.014-7.92 6.464-12.973 6.464-10.515 0-18.982-10.752-18.982-24.02 0-8.37 3.414-15.788 8.603-20.075 3.005-2.518 6.555-3.947 10.447-3.947 10.447 0 18.914 10.752 18.914 24.021Z',
              fill: '#E9AD38',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M133.983 29.767c0 6.941-2.322 13.133-6.009 17.557-3.004 2.517-6.555 3.946-10.447 3.946-10.515 0-18.982-10.751-18.982-24.02 0-6.942 2.322-13.134 6.009-17.557 3.004-2.518 6.555-3.947 10.447-3.947 10.515 0 18.982 10.752 18.982 24.021Z',
              fill: '#F1CC50',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M98.386 36.539H95.99c-1.629 0-2.97-1.498-2.97-3.317v-.748c0-1.82 1.341-3.317 2.97-3.317h2.396v7.382Z',
              fill: '#E9AD38',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M130.579 36.54h2.695c1.833 0 3.342-1.362 3.342-3.016v-.68c0-1.654-1.509-3.015-3.342-3.015h-2.695v6.71Z',
              fill: '#F1CC50',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M104.622 9.284s-1.366 9.935 9.901 8.574c11.266-1.36 18.777 4.355 19.664 11.569 0 0 7.033-7.418 2.117-16.468-3.141-5.716-21.372-23.204-31.682-3.675Z',
              fill: '#684023',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M108.992 6.903s-7.306-6.6-12.973.953c-7.238 9.594.355 22.98.355 22.98s1.284-12.093 5.722-12.977c4.438-.817 13.041-5.716 6.896-10.956Z',
              fill: '#684023',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              fillRule: 'evenodd',
              clipRule: 'evenodd',
              d: 'M130.915 91.565c-.781 0-1.391.342-1.794.897-.391.537-.554 1.227-.554 1.885 0 .695.246 1.477.625 2.087.372.597.966 1.17 1.723 1.17.755 0 1.349-.57 1.721-1.167.38-.61.626-1.392.626-2.091 0-.656-.155-1.347-.545-1.886-.403-.558-1.015-.895-1.802-.895Zm-1.278 2.782c0-.453.114-.857.315-1.134.189-.26.486-.454.963-.454.49 0 .785.195.969.45.197.273.309.675.309 1.138 0 .42-.16.969-.435 1.41-.283.454-.595.655-.843.655-.247 0-.56-.202-.843-.658-.276-.443-.435-.992-.435-1.407Z',
              fill: '#A0C9FF',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M217.697 114.01c-.105 1.043-.818 3.514-.949 3.837-1.033 2.779-8.007 4.963-8.165 4.945-.158-.019-2.344-1.099-2.357-1.255-.012-.156 1.965-1.819 4.105-3.179 3.044-1.935 4.374-5.709 4.263-5.919-.111-.211-2.064-1.121-2.933-1.397-.868-.276-1.65-1.436.158-1.956 1.808-.52 3.986.059 5.086.88 1.1.822.897 3.001.792 4.044Z',
              fill: '#F1CC50',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M127.37 100.006c-1.005-.118-1.835.733-1.835 1.605 0 .866.645 1.653 1.859 1.653.437 0 .883-.203 1.372-.581.571-.439 1.044-.956 1.518-1.473.475-.52.951-1.039 1.525-1.482.57-.439 1.221-.78 1.956-.78 1.674 0 2.851 1.168 2.851 2.702 0 1.53-1.376 2.831-2.936 2.648-.627-.073-1.362-.297-1.953-.907l.692-.752c.376.388.868.557 1.369.616h.001c1.005.118 1.835-.733 1.835-1.604 0-.867-.645-1.653-1.859-1.653-.437 0-.882.202-1.372.58-.571.44-1.043.956-1.518 1.474-.475.519-.951 1.038-1.525 1.481-.57.44-1.221.781-1.956.781-1.674 0-2.851-1.168-2.851-2.703 0-1.53 1.376-2.83 2.936-2.647.627.073 1.362.297 1.953.907l-.692.752c-.376-.388-.868-.558-1.369-.616l-.001-.001Z',
              fill: '#A0C9FF',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M93.69 130.019c-2.717 2.858-5.295 6.717-6.48 9.862l-.557.335c-5.784-3.144-10.427-4.945-15.096-6.375-3.066-.929-8.736-11.755 6.733-9.825 2.021.286 4.809 1.215 8.153 2.644.07.072.14.143.21.143h.07c2.09.858 4.459 1.93 6.968 3.216Z',
              fill: '#C22704',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M92.553 142.212c-1.164 1.274-6.65-.721-6.263-2.328.75-3.108 3.348-6.432 6.983-9.92 2.383-1.441 8.079 2.228 6.706 3.88-1.33 2.66-6.297 7.13-7.426 8.368Z',
              fill: '#E0E7F2',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M149.024 112.032c-2.683 2.148-5.59 3.579-6.707 4.027-1.006.671 0 2.013 1.006 1.677 1.006-.335 0 0 4.359-2.013 4.36-2.013 9.055-8.053 11.067-11.743.914-1.678-.709-4.024-2.012-1.678-1.677 3.02-4.36 7.046-7.713 9.73Z',
              fill: '#A0C9FF',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M8.813 114.885c.105 1.043.818 3.514.949 3.837 1.033 2.779 8.007 4.963 8.165 4.945.158-.019 2.344-1.099 2.357-1.255.012-.156-1.965-1.819-4.105-3.179-3.043-1.935-4.373-5.709-4.263-5.919.111-.211 2.064-1.121 2.933-1.397.868-.276 1.65-1.436-.158-1.956-1.808-.52-3.986.059-5.086.88-1.1.822-.897 3.001-.792 4.044Z',
              fill: '#F1CC50',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'm125.813 60.664-.001-.005c0-1.406 2.938 0 2.719 1.157-.125 7.843-1.5 9.156-3.187 11.374-1.688 2.22-8.906 6.282-18.094 2.594-9.187-3.687-7.906-11.625-7.375-13.531.531-1.906 2.281-.344 2.156 0-1.406 8.031 3.407 10.344 5.938 11.594 3.116 1.539 11.594 1.469 15.687-2.031 3.273-2.799 2.48-8.733 2.157-11.152Z',
              fill: '#A0C9FF',
            },
            void 0
          ),
        ],
      }
    ),
    void 0
  )
exports.GuruImage = GuruImage
const Trader = () =>
  (0, jsx_runtime_1.jsxs)(
    'svg',
    Object.assign(
      {
        width: '24',
        height: '24',
        viewBox: '0 0 24 24',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
      },
      {
        children: [
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M7.33831 7.41489C7.33831 15.577 12.2407 15.4368 11.1842 15.7727C5.96489 17.4313 4.62055 9.455 4.1277 9.455C2.35464 9.455 0 9.1042 0 7.41489C0 5.72392 1.8342 3.33301 4.58626 3.33301C6.36007 3.33301 7.33831 5.72392 7.33831 7.41489Z',
              fill: '#77B255',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M11.4292 19.0474C11.4292 21.2562 10.3634 21.333 9.04835 21.333C7.73335 21.333 6.66669 21.2554 6.66669 19.0474C6.66669 18.6562 6.73752 18.2242 6.86169 17.7778C7.43669 15.7058 9.15669 13.333 10.2384 13.333C11.5534 13.333 11.4292 16.8386 11.4292 19.0474ZM18.5717 19.0474C18.5717 21.2562 19.6367 21.333 20.9517 21.333C22.2675 21.333 23.3334 21.2554 23.3334 19.0474C23.3334 18.6562 23.2625 18.2242 23.1384 17.7778C22.5625 15.7058 20.8434 13.333 19.7617 13.333C18.4467 13.333 18.5717 16.8386 18.5717 19.0474Z',
              fill: '#77B255',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M24 14C24 17.3333 19.8211 17.3333 14.6666 17.3333C9.51221 17.3333 5.33331 17.3333 5.33331 14C5.33331 8.4775 9.51221 4 14.6666 4C19.8211 4 24 8.4775 24 14Z',
              fill: '#3E721D',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M3.99998 6.66699C4.36817 6.66699 4.66665 6.21928 4.66665 5.66699C4.66665 5.11471 4.36817 4.66699 3.99998 4.66699C3.63179 4.66699 3.33331 5.11471 3.33331 5.66699C3.33331 6.21928 3.63179 6.66699 3.99998 6.66699Z',
              fill: '#292F33',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              d: 'M16.1995 12.083C16.1995 15.0755 15.8638 15.333 14.6667 15.333C13.4695 15.333 13.1338 15.0747 13.1338 12.083C13.1338 9.09134 13.4688 5.33301 14.6667 5.33301C15.8645 5.33301 16.1995 9.09134 16.1995 12.083ZM21.1693 11.1663C21.7655 14.068 20.6327 14.4997 19.4355 14.4997C18.2383 14.4997 17.7017 15.0747 17.7017 12.083C17.7017 9.09134 16.9378 6.66634 18.135 6.66634C19.3315 6.66634 20.4848 7.83301 21.1693 11.1663ZM11.6323 12.083C11.6323 15.0755 11.095 14.4997 9.89781 14.4997C8.70064 14.4997 7.5678 14.068 8.16399 11.1663C8.84849 7.83301 10.3893 6.66634 11.5865 6.66634C12.7843 6.66634 11.6323 9.09134 11.6323 12.083Z',
              fill: '#5C913B',
            },
            void 0
          ),
        ],
      }
    ),
    void 0
  )
exports.Trader = Trader
const Copy = () =>
  (0, jsx_runtime_1.jsx)(
    'svg',
    Object.assign(
      {
        width: '24',
        height: '24',
        viewBox: '0 0 24 24',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
      },
      {
        children: (0, jsx_runtime_1.jsx)(
          'path',
          {
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            d: 'M7.54545 5.63636C7.54545 3.62806 9.17351 2 11.1818 2H16.6364C18.6447 2 20.2727 3.62806 20.2727 5.63636V13.8182C20.2727 15.8265 18.6447 17.4545 16.6364 17.4545H15.7273V18.3636C15.7273 20.3719 14.0992 22 12.0909 22H6.63636C4.62806 22 3 20.3719 3 18.3636V10.1818C3 8.17351 4.62806 6.54545 6.63636 6.54545H7.54545V5.63636ZM11.1818 15.6364C10.1777 15.6364 9.36364 14.8223 9.36364 13.8182V5.63636C9.36364 4.63221 10.1777 3.81818 11.1818 3.81818H13.9091V5.63636C13.9091 7.14259 15.1301 8.36364 16.6364 8.36364H18.4545V13.8182C18.4545 14.8223 17.6405 15.6364 16.6364 15.6364H11.1818ZM18.4545 6.54545V5.63636C18.4545 4.63221 17.6405 3.81818 16.6364 3.81818H15.7273V5.63636C15.7273 6.13844 16.1343 6.54545 16.6364 6.54545H18.4545ZM7.54545 13.8182V8.36364H6.63636C5.63221 8.36364 4.81818 9.17766 4.81818 10.1818V18.3636C4.81818 19.3678 5.63221 20.1818 6.63636 20.1818H12.0909C13.0951 20.1818 13.9091 19.3678 13.9091 18.3636V17.4545H11.1818C9.17351 17.4545 7.54545 15.8265 7.54545 13.8182Z',
            fill: '#9488F0',
          },
          void 0
        ),
      }
    ),
    void 0
  )
exports.Copy = Copy
const Discord = () =>
  (0, jsx_runtime_1.jsxs)(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 32 32',
        fill: '#fff',
        width: '32',
        height: '32',
      },
      {
        children: [
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              fillRule: 'evenodd',
              clipRule: 'evenodd',
              d: 'M19.77 14.825c-.226.286-.37.713-.37 1.189 0 .475.144.903.37 1.188.203.258.5.438.962.438.462 0 .758-.18.962-.438.225-.285.368-.713.368-1.188 0-.476-.143-.903-.368-1.188-.204-.259-.5-.439-.962-.439-.463 0-.759.18-.963.438Zm-1.625-1.283c.585-.74 1.472-1.225 2.587-1.225 1.114 0 2.002.485 2.586 1.225.563.713.815 1.616.815 2.472 0 .855-.252 1.758-.815 2.471-.584.74-1.472 1.225-2.586 1.225-1.115 0-2.002-.485-2.587-1.225-.563-.713-.814-1.616-.814-2.471 0-.856.25-1.76.814-2.472ZM11.268 14.387c-.624 0-1.331.61-1.331 1.627 0 1.016.707 1.626 1.33 1.626.625 0 1.332-.61 1.332-1.626 0-1.017-.707-1.627-1.331-1.627Zm-3.401 1.627c0-1.924 1.411-3.697 3.4-3.697 1.99 0 3.402 1.773 3.402 3.697 0 1.923-1.412 3.696-3.401 3.696-1.99 0-3.401-1.773-3.401-3.696Z',
            },
            void 0
          ),
          (0, jsx_runtime_1.jsx)(
            'path',
            {
              fillRule: 'evenodd',
              clipRule: 'evenodd',
              d: 'M18.015 7.502a2.135 2.135 0 0 1 2.447-1.593l3.51.625a5.12 5.12 0 0 1 4.099 3.918l1.713 7.624a5.12 5.12 0 0 1-2.583 5.639l-2.37 1.266a3.075 3.075 0 0 1-4.384-1.8c-3.107.474-5.788.474-8.894 0a3.075 3.075 0 0 1-4.385 1.8l-2.35-1.255a5.102 5.102 0 0 1-2.56-5.675l1.592-6.736.012-.052a9.44 9.44 0 0 1 .24-.9 5.175 5.175 0 0 1 3.06-3.148c.245-.092.509-.163.892-.265l.051-.014 3.46-.924A1.972 1.972 0 0 1 14.001 7.5a22.027 22.027 0 0 1 4.014.002Zm2.084.445a.063.063 0 0 0-.074.062c0 1.03-.93 1.717-1.845 1.595-1.229-.162-3.018-.166-4.277-.01-.917.113-1.85-.543-1.924-1.55l-3.34.892c-.454.121-.61.164-.745.215a3.105 3.105 0 0 0-1.835 1.89c-.048.136-.086.293-.194.75l-1.593 6.736A3.032 3.032 0 0 0 5.794 21.9l2.35 1.255a1.004 1.004 0 0 0 1.452-.665 1.811 1.811 0 0 1 2.054-1.387c3.064.486 5.635.486 8.7 0a1.811 1.811 0 0 1 2.053 1.387c.145.647.867.978 1.453.665l2.37-1.265a3.05 3.05 0 0 0 1.538-3.359l-1.713-7.624a3.05 3.05 0 0 0-2.441-2.334l-3.51-.625Z',
            },
            void 0
          ),
        ],
      }
    ),
    void 0
  )
exports.Discord = Discord
const Telegram = () =>
  (0, jsx_runtime_1.jsx)(
    'svg',
    Object.assign(
      { xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 32 32', width: '32', height: '32' },
      {
        children: (0, jsx_runtime_1.jsx)(
          'path',
          {
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            d: 'M25.835 4.242c-1.17-.86-2.525-.705-3.894-.236-1.348.463-3.04 1.347-5.14 2.446l-9.677 5.06c-1.089.57-1.971 1.031-2.618 1.445-.628.401-1.25.885-1.565 1.588a3.646 3.646 0 0 0 .2 3.356c.396.658 1.07 1.057 1.741 1.374.691.326 1.621.67 2.769 1.093l.051.019c.212.078.332.123.42.161a.71.71 0 0 1 .083.04c.041.03.076.069.102.113.003.006.014.03.034.089.031.095.067.222.13.448l1.08 3.886c.14.509.257.928.36 1.247.095.293.223.655.436.942.98 1.33 2.864 1.426 3.963.203.238-.264.4-.612.521-.893.133-.308.287-.713.476-1.204l.01-.027c.097-.254.157-.41.206-.523.031-.073.048-.103.052-.11a.363.363 0 0 1 .405-.132 1.1 1.1 0 0 1 .103.06c.102.065.235.157.453.31l.509.354c1.038.724 1.882 1.312 2.585 1.71.712.403 1.43.702 2.203.68a4.024 4.024 0 0 0 2.923-1.414c.518-.6.766-1.366.934-2.195.165-.818.284-1.871.43-3.167l.797-7.085c.274-2.44.495-4.404.453-5.887-.043-1.506-.364-2.891-1.535-3.751Zm-8.123 4.346c2.21-1.155 3.753-1.959 4.925-2.36 1.17-.402 1.63-.282 1.914-.073.284.208.549.62.585 1.908.037 1.29-.162 3.08-.45 5.647l-.779 6.923c-.154 1.369-.261 2.315-.403 3.014-.143.702-.289.977-.407 1.114-.34.395-.82.627-1.329.643-.177.005-.471-.057-1.075-.398-.601-.341-1.36-.868-2.455-1.633l-.462-.322-.089-.061c-.325-.228-.66-.462-1-.58-1.058-.367-2.222.012-2.896.94-.18.248-.312.56-.435.875l.173-2.354a5.194 5.194 0 0 1 1.095-2.837l5.874-7.437c1.078-1.365-.637-3.195-1.963-2.096l-7.042 5.838a1.205 1.205 0 0 0-.181 1.641c.383.506 1.086.591 1.57.19l.914-.758-.895 1.134a7.59 7.59 0 0 0-1.6 4.146l-.079 1.073-.605-2.18-.024-.085c-.094-.341-.195-.707-.385-1.029a2.682 2.682 0 0 0-.722-.798c-.296-.217-.64-.343-.96-.46l-.08-.03c-1.211-.447-2.05-.758-2.644-1.038-.623-.295-.752-.46-.77-.49a1.215 1.215 0 0 1-.067-1.119c.015-.032.123-.212.706-.585.556-.355 1.352-.773 2.5-1.373l9.54-4.99Z',
            fill: '#fff',
          },
          void 0
        ),
      }
    ),
    void 0
  )
exports.Telegram = Telegram
const Twitter = () =>
  (0, jsx_runtime_1.jsx)(
    'svg',
    Object.assign(
      { xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 32 32', width: '32', height: '32' },
      {
        children: (0, jsx_runtime_1.jsx)(
          'path',
          {
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            d: 'M18.19 6.84c-1.521.815-2.612 2.61-1.983 5.186a1 1 0 0 1-.972 1.237c-2.906 0-5.73-1.267-8.185-3.09-.71-.527-1.153-.853-1.484-1.031a2.425 2.425 0 0 0-.039-.021c-.058.296-.08.738-.112 1.477-.184 4.2 1.428 7.839 5.263 10.644a1 1 0 0 1-.134 1.697c-.948.485-2.743.91-4.438 1.198l-.341.058.192.072c5.937 2.227 11.08 1.652 14.54-.702 3.437-2.338 5.41-6.565 4.775-12.095a1 1 0 0 1 .537-1.005c.324-.165.768-.605 1.158-1.302a5.548 5.548 0 0 0 .685-2.184l-.223.093c-.605.252-1.272.476-1.853.638-.539.15-1.126.283-1.517.283a1 1 0 0 1-.715-.3c-1.509-1.545-3.593-1.69-5.154-.853Zm9.49-.565v.003-.003ZM5.58 8.927l-.002.005a.025.025 0 0 1 .003-.005Zm-.24.114a.034.034 0 0 1-.009-.001l.009.001Zm19.062-3.1a6.277 6.277 0 0 0-7.158-.864c-2.03 1.09-3.416 3.285-3.195 6.1-1.954-.278-3.936-1.22-5.807-2.61l-.06-.043c-.631-.47-1.192-.886-1.665-1.142-.481-.26-1.17-.52-1.892-.177-.68.323-.936.936-1.048 1.47-.101.478-.128 1.096-.157 1.76l-.003.076c-.189 4.298 1.286 8.136 4.643 11.176-.663.17-1.452.336-2.288.478l-.06.01c-.798.135-1.477.25-1.956.384a2.81 2.81 0 0 0-.762.316c-.253.163-.585.478-.634.993-.048.494.193.862.413 1.08.203.2.454.346.67.456.427.216 1.04.446 1.752.713l.06.022c6.383 2.396 12.241 1.887 16.367-.92 4.008-2.727 6.194-7.498 5.698-13.366.552-.444 1.027-1.06 1.393-1.716.503-.901.869-1.996.94-3.107l.004-.053c.019-.286.04-.608.015-.877-.03-.318-.143-.789-.596-1.113-.516-.369-1.088-.238-1.354-.164-.293.082-.648.23-1.012.382l-.05.02c-.52.218-1.11.416-1.621.559a9.53 9.53 0 0 1-.637.157Z',
            fill: '#fff',
          },
          void 0
        ),
      }
    ),
    void 0
  )
exports.Twitter = Twitter
